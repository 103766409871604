import { EventEmitter, Injectable } from '@angular/core';
import { PUSHER_CLUSTER } from '@app/shared/consts/pusher-cluster';
import { PusherEvent } from '@app/shared/enums/pusher-event';
import { environment } from '@environments/environment';
import { captureMessage } from '@sentry/angular-ivy';
import Pusher, { Channel } from 'pusher-js';

@Injectable({ providedIn: 'root' })
export class PusherService {
  /** Pusher client instance. */
  private readonly pusher = new Pusher(environment.pusher, { cluster: PUSHER_CLUSTER });

  /** Pusher channel. */
  private channel?: Channel;

  /** Triggers when an event comes from the pusher. */
  public readonly events: Record<PusherEvent, EventEmitter<unknown>> = this.generateEvents();

  constructor() {
    this.pusher.connection.bind('connected', (): void => {
      console.debug('[PusherService] Connected to pusher');
    });
    this.pusher.connection.bind('failed', (): void => {
      console.debug('[PusherService] Failed to connect to pusher');
      captureMessage('[PusherService] Failed to connect to pusher');
    });
    this.pusher.bind_global((event: PusherEvent, data: unknown): void => {
      if (event in this.events) {
        this.events[event].emit(data);
      } else if (!event.includes('pusher')) {
        const message: string = `[PusherService] ${event} was not registered as an emitter.`;
        console.warn(message);
        captureMessage(message);
      }
      console.debug(`[PusherService] ${event}`, data);
    });
  }

  /** @returns value for {@link events}. */
  private generateEvents(): Record<PusherEvent, EventEmitter<unknown>> {
    const events = {} as Record<PusherEvent, EventEmitter<unknown>>;
    for (const event of Object.values(PusherEvent)) {
      events[event] = new EventEmitter();
    }
    return events;
  }

  /** Connect to pusher channel. */
  public connectToChannel(channel: string, info: string): void {
    if (this.channel) {
      this.channel.unbind_all();
      this.channel.unsubscribe();
    }
    this.channel = this.pusher.subscribe(channel);
    this.channel.bind('pusher:subscription_succeeded', (): void => {
      console.debug(`[PusherService] Connected to channel "${info}"`);
    });
    this.channel.bind('pusher:subscription_error', (): void => {
      console.debug(`[PusherService] Failed to connect to channel "${info}"`);
      captureMessage(`[PusherService] Failed to connect to channel "${info}"`);
    });
  }

  /** Start pusher connection. */
  public connect(): void {
    if (this.pusher.connection.state === 'disconnected') {
      this.pusher.connect();
    }
  }

  /** Disconnect from all channels, events, and pusher. */
  public disconnect(): void {
    if (this.pusher.connection.state === 'connected') {
      console.debug('[PusherService] Disconnecting from pusher');
      this.pusher.disconnect();
    } else {
      console.debug(`[PusherService] Skipped disconnecting from pusher (state is ${this.pusher.connection.state})`);
    }
  }
}
