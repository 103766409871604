import { ApiError, findItemInList, HttpErrorResponse, ReactiveForm, RouterLink } from '@amirsavand/ngx-common';
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppService } from '@app/app.service';
import { ApiService } from '@app/shared/services/api.service';
import {
  IsTypingReceiver,
  Member,
  MemberRole,
  MessageApi,
  MessageAttachment,
  MessageKind,
  MessagePusherData,
  MessageReaction,
  MessageReactionPusherData,
  PusherEventOperation,
  Room,
  RoomKind,
  TenantService,
} from '@app/tenant';
import { RoomService } from '@app/tenant/room';
import { API_SERVICE, stripTags } from '@SavandBros/savandbros-ngx-common';

export class Message {
  /** Injected service. */
  private readonly tenantService: TenantService = this.injector.get(TenantService);

  /** Injected service. */
  private readonly roomService: RoomService = this.injector.get(RoomService);

  /** Injected service. */
  private readonly appService: AppService = this.injector.get(AppService);

  /** Injected service. */
  private readonly apiService = this.injector.get(API_SERVICE) as ApiService;

  /** Injected service. */
  private readonly domSanitizer: DomSanitizer = this.injector.get(DomSanitizer);

  /** Used for updating this message. */
  public readonly formControl = new FormControl<string>('', { nonNullable: true });

  /** Reactive form to be used for editing. */
  public readonly form: ReactiveForm<MessageApi> = new ReactiveForm<MessageApi>({
    form: new FormGroup({
      content: this.formControl,
    }),
  });

  /** Unique link of this message. */
  public readonly link: RouterLink = this.tenantService.getPath([this.room.id], {
    message: this.init.id,
    thread: this.init.parent,
  });

  /** Unique ID of this message element for template. */
  public readonly elementId: string = `message-${this.init.id}`;

  /** Unique ID of this message add-reaction element for template. */
  public readonly addReactionElementId = `message-${this.init.id}-add-reaction`;

  /** Unique ID of this message reply-in-thread element for template. */
  public readonly replyInThreadElementId = `message-${this.init.id}-reply-in-thread`;

  /** Is typing receiver instance. */
  public readonly isTyping = new IsTypingReceiver();

  /** Unique ID of this message. */
  public id: number = this.init.id;

  /** Kind of this message. */
  public kind: MessageKind = this.init.kind;

  /** Member who created this message. */
  public member: Member = this.tenantService.getMemberById(this.init.member);

  /** Content of this message. */
  public content: string = this.init.content;

  /** Safe content of this message. */
  public contentSafe: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.content);

  /** Number of replies of this message. */
  public repliesCount = this.init.replies_count;

  /** Date of latest reply of this message. */
  public repliesLastTime: Date | null = null;

  /** Members who replied to this message. */
  public repliesMembers: Member[] = (this.init.replies_members || []).map(
    (id: number): Member => this.tenantService.getMemberById(id),
  );

  /** Parent of this message (if is a message of a thread). */
  public parent: number | null = this.init.parent;

  /** Message reactions. */
  public reactions: MessageReaction[] = [];

  /** Message attachments. */
  public attachments: MessageAttachment[] = this.init.attachments;

  /** Creation date of this message. */
  public created: Date = new Date(this.init.created);

  /** Updated date of this message. */
  public updated: Date = new Date(this.init.updated);

  /** Is this message in form mode? */
  public formMode = false;

  /** Is authenticated member allowed to delete this message? */
  public canDestroy = false;

  /** Is authenticated member allowed to edit this message? */
  public canUpdate = false;

  /** API loading indicator to disable UI actions. */
  public loading = false;

  /** API error indicator for UI. */
  public errors: ApiError | null = null;

  /** String version of created date. */
  public get createdRaw(): string {
    return this.init.created;
  }

  constructor(
    private readonly init: MessageApi,
    public readonly room: Room,
    private readonly injector: Injector,
  ) {
    this.generateRepliesLastTime();
    this.generateReactions();
    this.generateCanDestroy();
    this.generateCanUpdate();
    this.generateContentSafe();
  }

  /** Generate value for {@link repliesLastTime}. */
  public generateRepliesLastTime(): void {
    if (this.init.last_replied) {
      this.repliesLastTime = new Date(this.init.last_replied);
    } else {
      this.repliesLastTime = null;
    }
  }

  /** Generate value for {@link reactions}. */
  public generateReactions(): void {
    this.reactions.length = 0;
    for (const reaction in this.init.reactions) {
      const members: Member[] = this.init.reactions[reaction].map(
        (id: number): Member => this.tenantService.getMemberById(id),
      );
      this.reactions.push(new MessageReaction({ reaction, members, message: this }, this.injector));
    }
  }

  /** Generate value for {@link canDestroy}. */
  public generateCanDestroy(): void {
    /** Can delete if message member is authenticated member. */
    if (this.member.id === this.tenantService.authenticatedMember.id) {
      this.canDestroy = true;
    } else if (this.room.kind === RoomKind.DIRECT) {
      /** Can not delete if message member is not authenticated member in direct room. */
      this.canDestroy = false;
    } else if ([MemberRole.ADMINISTRATOR, MemberRole.OWNER].includes(this.tenantService.authenticatedMember.role)) {
      /** Can delete if authenticated member is tenant owner/admin. */
      this.canDestroy = true;
    }
  }

  /** Generate value for {@link canUpdate}. */
  public generateCanUpdate(): void {
    /** Can delete if message member is authenticated member. */
    this.canUpdate = this.member.id === this.tenantService.authenticatedMember.id;
  }

  /** Generate value for {@link contentSafe}. */
  public generateContentSafe(): void {
    let content = this.content;
    if (content === '<p></p>') {
      content = '';
    }
    this.contentSafe = this.domSanitizer.bypassSecurityTrustHtml(content);
  }

  /** Delete this message. */
  public destroy(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.form.loading = true;
    this.apiService.message.destroy(this.id).subscribe({
      next: (): void => {
        this.form.loading = false;
        this.loading = false;
        this.errors = null;
      },
      error: (error: HttpErrorResponse): void => {
        this.form.loading = false;
        this.loading = false;
        this.errors = error.error;
      },
    });
  }

  /**
   * Update this message (API call).
   *
   * If message content is empty, message is deleted.
   *
   * @param event When given, prevent-default is triggered.
   */
  public update(event?: Event): void {
    if (this.loading) {
      return;
    }
    const content: string = this.formControl.value;
    if (!stripTags(content).trim().length && !this.attachments.length) {
      if (this.canDestroy) {
        this.destroy();
      }
      return;
    }
    this.loading = true;
    this.form.loading = true;
    this.apiService.message.update(this.id, { content }).subscribe({
      next: (data: MessageApi): void => {
        this.form.loading = false;
        this.loading = false;
        this.errors = null;
        this.content = data.content;
        this.generateContentSafe();
        this.setFormMode(false);
      },
      error: (error: HttpErrorResponse): void => {
        this.form.loading = false;
        this.loading = false;
        this.errors = error.error;
      },
    });
    event?.preventDefault();
  }

  /**
   * Change this message form mode.
   * @param status New form mode status.
   */
  public setFormMode(status: boolean): void {
    if (status) {
      this.formControl.setValue(this.content);
    } else {
      this.formControl.setValue('');
    }
    this.formMode = status;
  }

  /**
   * Handles reaction events.
   *
   * Must be triggered when reaction event comes from
   * pusher.
   *
   * @param data Message reaction pusher data.
   */
  public onReactionChange(data: MessageReactionPusherData): void {
    const reaction: MessageReaction | undefined = findItemInList(
      this.reactions,
      data.data.reaction.reaction,
      'reaction',
    );
    if (reaction) {
      reaction.onChange(data);
    } else {
      this.reactions.push(
        new MessageReaction(
          {
            message: this,
            members: [data.member],
            reaction: data.data.reaction.reaction,
          },
          this.injector,
        ),
      );
    }
  }

  /**
   * Handles replies change.
   *
   * Must be triggered when a message under this message
   * changes (reply).
   *
   * @param data Message pusher data.
   */
  public onReplyChange(data: MessagePusherData): void {
    /** Make sure the message is a reply to this message. */
    if (data.data.parent !== this.id) {
      return;
    }
    /** On reply added */
    if (data.operation === PusherEventOperation.CREATE) {
      /** Store message data. */
      const reply: Message = data.message as Message;
      /** Sync replies count. */
      this.repliesCount++;
      /** Sync last reply date. */
      this.repliesLastTime = new Date(data.data.created);
      /** Sync reply members. */
      if (!this.repliesMembers.includes(reply.member)) {
        this.repliesMembers.push(reply.member);
      }
    } else if (data.operation === PusherEventOperation.DESTROY) {
      /** On reply removed. */
      /** Sync replies count. */
      this.repliesCount--;
    }
  }

  /**
   * Add reaction for this message by authenticated member.
   * @param reaction Reaction unified value.
   */
  public react(reaction: string): void {
    this.loading = true;
    this.form.loading = true;
    this.roomService.reactToMessage(this, reaction).subscribe({
      next: (): void => {
        this.form.loading = false;
        this.loading = false;
      },
      error: (error: HttpErrorResponse): void => {
        this.form.loading = false;
        this.loading = false;
        this.appService.alertErrorApi(error, 'Failed to react to message.');
      },
    });
  }
}
